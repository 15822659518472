import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import Breadcrumb from '../../../../components/Breadcrumb';

const SatelliteData = ({pageContext}) => (
	<Layout>
		<SEO title="Satellite data" />
		<Hero>
			<Breadcrumb crumbs={pageContext?.crumbs} />
			<h1>Satellite data</h1>
		</Hero>
		<Block light>
			<Content>
				<Subnavigation>
					{/*<Anchors>*/}
					{/*	<a href={'#airbus'}>Airbus</a>*/}
					{/*	<a href={'#planet'}>Planet</a>*/}
					{/*	<a href={'#summary'}>Summary table of provided satellite imagery</a>*/}
					{/*</Anchors>*/}

					<PersonBadge
						note={pageContext.service.contactNote}
						email={pageContext.service.contactPerson}
					/>
				</Subnavigation>
			</Content>
		</Block>
		<Block light>
			<Content>
				<p>
					Based on the long-term partnerships with all major commercial
					satellite operators and satellite data providers Gisat represents the
					centre point for all clients interested in satellite data acquisition.
					Clients can take advantage of professional consulting and advising
					services related to the choice of the most suitable data, accurate
					processing and integration with existing tools and databases. Thanks
					to our long-term experience with the use of satellite imagery in
					diverse application areas we can guarantee cost effective and
					straightforward solutions customised to the clients needs and
					requirements.
				</p>
				<p>
					Gisat provides a full range of services starting from archive search
					to identify satellite products already available up to the various
					levels of satellite tasking to acquire new imagery according to the
					clients specification.
				</p>

				<p>
					Gisat also provides full support to the clients interested in the use
					of free satellite imagery, such as data acquired by Landsats or
					Sentinels satellites. Based on our extensive experiences in using
					these datasets we may recommend appropriate source archives, automate
					the data search & download and apply various pre-processing routines
					to provide tailored satellite data products to every client.
				</p>
			</Content>
		</Block>
	</Layout>
);

SatelliteData.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default SatelliteData;
